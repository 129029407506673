import Table from "src/components/table/Table";
import {
  Actions,
  TableProps,
  topToolbarProps,
} from "src/components/table/props";
import useTable from "src/components/table/use-table";
import { useEffect } from "react";

interface Props<T> {
  data: T[];
  dataSchema: { [key: string]: any };
  count?: number;
  hiddenColumns?: (keyof T)[];
  columnsVisibility?: Record<keyof T, boolean>;
  actions?: Actions[];
  loading?: boolean;
  error?: any;
  setPaginationInfo?: React.Dispatch<
    React.SetStateAction<Partial<TableProps> | undefined>
  >;
  isDetailed?: boolean;
  isCheckBox?: boolean;
  topToolbar?: topToolbarProps[];
}

export default function CustomerTable<T>({
  data,
  dataSchema,
  count = data.length,
  hiddenColumns = [],
  columnsVisibility = {} as Record<keyof T, boolean>,
  loading = false,
  error,
  actions = [],
  setPaginationInfo,
  isDetailed = true,
  isCheckBox = true,
  topToolbar = [],
}: Props<T>) {
  let rowPerPage = [5, 7, 8];

  const {
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
    order,
    orderBy,
    onSort,
  } = useTable({
    defaultDense: true,
    defaultOrder: "asc",
    defaultOrderBy: "id",
    defaultSelected: [],
    defaultRowsPerPage: 3,
    defaultCurrentPage: 1,
  });

  useEffect(() => {
    if (setPaginationInfo) {
      setPaginationInfo({
        page,
        rowsPerPage,
      });
    }
  }, [page, rowsPerPage, order, orderBy, setPaginationInfo]);

  // const renderCell = {
  //     details: (jsn: any) => {
  //         return <IconButton
  //             onClick={() => handleDetailTechnician?.(jsn.id)}
  //         >
  //             <Iconify icon="hugeicons:information-circle" />
  //         </IconButton>
  //     }
  // }
  return (
    <>
      {/* <Table
        data={data}
        dataSchema={dataSchema}
        actions={actions}
        loading={loading}
        error={error}
        total={count}
        pageSize={rowsPerPage}
        setPageSize={onChangeRowsPerPage}
        page={page}
        orderBy={orderBy}
        order={order}
        setPage={onChangePage}
        rowPerPage={rowPerPage}
        // renderCell={renderCell}
        hiddenColumns={hiddenColumns as string[]}
        columnsVisibility={columnsVisibility}
        iconExpand={{
          title: "Details",
          // renderCell: () => <Icon color='primary'>chevron_right</Icon>,
        }}
      /> */}

      <Table
        data={data}
        dataSchema={dataSchema}
        actions={actions}
        loading={loading}
        error={error}
        total={count}
        pageSize={rowsPerPage}
        setPageSize={onChangeRowsPerPage}
        page={page}
        orderBy={orderBy}
        order={order}
        setPage={onChangePage}
        rowPerPage={rowPerPage}
        hiddenColumns={hiddenColumns as string[]}
        columnsVisibility={columnsVisibility}
        disableDetail={!isDetailed}
        iconExpand={{
          title: "Details",
          // renderCell: () => <Icon color='primary'>chevron_right</Icon>,
        }}
        checkBoxSelection={isCheckBox}
        topToolbar={topToolbar}
      />
    </>
  );
}
