import axiosInstance, { endpoints } from "src/utils/axios";
import { downloadToFile } from "src/utils/download-file";

export type postContactType = {
  cardId: string | undefined;
  name: String | null;
  phone: String | null;
  email: String | null;
  role: String | null;
  company: String | null;
  description: String | null;
};

export type postContactResponse = {
  success: boolean;
  code: string;
  message: string;
};

export type fetchContactType = {
  id: string;
  name: String | null;
  phone: String | null;
  email: String | null;
  role: String | null;
  company: String | null;
  description: String | null;
  cardId: String | null;
  createdAt: String | null;
  modifiedAt: String | null;
  modifiedBy: String | null;
  isDeleted: String | null;
  deletedAt: String | null;
  deletedBy: String | null;
};

export class ContactModel {
  private readonly postContactEndPoint = `${endpoints.hub.contact.postContact}`;
  private readonly fetchContactEndPoint = `${endpoints.hub.contact.fetchContact}`;
  private readonly deleteContactEndPoint = `${endpoints.hub.contact.deleteContact}`;
  private readonly downloadContactEndPoint = `${endpoints.hub.contact.downloadContact}`;

  public async postContact(data: any): Promise<postContactResponse | any> {
    try {
      const response: postContactResponse = (
        await axiosInstance.post(this.postContactEndPoint, data)
      ).data;
      return response;
    } catch (err) {
      return err;
    }
  }

  public async fetchContact(cardId: string): Promise<fetchContactType[]> {
    return (await axiosInstance.get(`${this.fetchContactEndPoint}/${cardId}`))
      .data;
  }

  public async deleteContact(contactId: string): Promise<boolean> {
    return (
      await axiosInstance.delete(`${this.deleteContactEndPoint}/${contactId}`)
    ).data;
  }

  public async downloadContact(
    cardId: string,
    fileName: string
  ): Promise<void> {
    const response = await axiosInstance.get(
      `${this.downloadContactEndPoint}/${cardId}`,
      {
        responseType: "blob", // ✅ Ensure response is treated as a file
      }
    );

    // Create a blob from the response
    downloadToFile(response.data, fileName, "text/csv");

    // return blob;
  }
}
